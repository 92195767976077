import { Box, Skeleton, Stack } from '@mui/material'
import Paper from 'components/paper'
import { StylesScrollBar } from 'styles/utils'

export function InfoSkeleton() {
  return (
    <>
      <Skeleton height={70} width={200} />
      <Skeleton height={50} width={350} />
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </>
  )
}
export function StatusSkeleton() {
  return (
    <>
      <Skeleton height={70} width={200} />
      <Skeleton height={50} width={350} />
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </>
  )
}

export function JobDetailSkeleton() {
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Skeleton height={60} width="50%" />
        <Skeleton height={60} width="30%" />
      </Stack>
      <Skeleton height={40} width="40%" />
      <Stack direction="row" gap={2} marginY={4}>
        <Skeleton height={400} width="60%" variant="rounded" animation="wave" />
        <Skeleton height={400} width="40%" variant="rounded" animation="wave" />
      </Stack>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Skeleton height={60} width="30%" />
        <Skeleton height={60} width="50%" />
      </Stack>
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
    </>
  )
}

export function CompanyDetailSkeleton() {
  return (
    <>
      <Stack marginTop={6} marginBottom={3} marginX={3}>
        <Skeleton height={70} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack marginTop={4} marginBottom={3} marginX={3}>
        <Skeleton height={70} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
      <Stack direction="row" marginY="2px" marginX={3} gap={2}>
        <Skeleton height={50} width={350} />
        <Skeleton height={50} width={350} />
      </Stack>
    </>
  )
}

export const JobsImportSkeleton = () => {
  const card = [1, 2, 3]
  return (
    <Box
      paddingX={1}
      paddingBottom={1}
      height="calc(100vh - 23em)"
      sx={{
        overflow: 'auto',
        ...StylesScrollBar
      }}
    >
      {card.map(item => (
        <Box
          key={item}
          component={Paper}
          elevation={3}
          padding={2}
          bgcolor="white"
          marginBottom={1}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={3}
            alignItems="center"
          >
            <Skeleton width={30} height={20} variant="rectangular" />
            <Skeleton height={40} width="80%" />
            <Skeleton width={50} variant="circular" />
          </Stack>
          <Box paddingLeft={7}>
            <Skeleton height={30} width="70%" />
            <Skeleton height={30} width="70%" />
            <Skeleton height={30} width="70%" />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export const JobsBoardSkeleton = () => {
  const card = Array.from(Array(5).keys())
  return (
    <>
      {card.map(item => (
        <Box
          key={item}
          component={Paper}
          elevation={3}
          padding={2}
          bgcolor="white"
          marginBottom={2}
        >
          <Stack
            gap={3}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="80%">
              <Skeleton height={40} width="50%" />
              <Skeleton height={30} width="40%" />
            </Box>
            <Skeleton width="20%" height={40} />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" width="60%" gap={2} alignItems="center">
              <Skeleton height={50} width={50} variant="circular" />
              <Skeleton height={70} width={70} />
              <Skeleton height={70} width="80%" />
            </Stack>
            <Skeleton width="30%" height={70} />
          </Stack>
        </Box>
      ))}
    </>
  )
}

export const Configurationkeleton = () => {
  const card = Array.from(Array(3).keys())

  return (
    <Stack gap={4} direction="row" flexWrap="wrap">
      {card.map(item => (
        <Box
          component={Paper}
          width={{ xs: '100%', sm: '40%', lg: '44%' }}
          key={item}
        >
          <Skeleton height={40} width="100%" />
          <Skeleton height={40} width="100%" />
          <Skeleton height={40} width="100%" />
          <Skeleton height={40} width="100%" />
          <Skeleton height={40} width="100%" />
          <Skeleton height={40} width="100%" />
        </Box>
      ))}
    </Stack>
  )
}

export const ProfileSkeleton = () => {
  return (
    <Box component={Paper} elevation={7} width="calc(100% - 250px)">
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
      <Skeleton height={60} width="100%" />
    </Box>
  )
}

export const ImportJobsSkeleton = () => {
  return (
    <Stack
      spacing={4}
      elevation={7}
      component={Paper}
      justifyContent="space-between"
      direction={{ xs: 'column', md: 'row' }}
    >
      <Box width={{ xs: '100%', md: '50%' }}>
        <Skeleton height={60} width="100%" />
        <Skeleton height={80} width="100%" />
        <Skeleton height={60} width="100%" variant="rounded" />
        <Box marginTop={4}>
          <JobsImportSkeleton />
        </Box>
      </Box>
      <Box width={{ xs: '100%', md: '50%' }}>
        <Skeleton height={60} width="100%" />
        <Skeleton height={80} width="100%" />
        <Skeleton height={60} width="100%" variant="rounded" />
        <Box marginTop={4}>
          <JobsImportSkeleton />
        </Box>
      </Box>
    </Stack>
  )
}

export const IntegrationSkeleton = () => {
  return (
    <Box component={Paper} width={{ xs: '100%', sm: '50%', lg: '44%' }}>
      <Stack justifyContent="flex-end" direction={'row'}>
        <Skeleton height={40} width="30%" variant="rounded" />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Skeleton height={100} width="40%" />
        <Skeleton height={100} width="60%" />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Skeleton height={60} width="30%" />
        <Skeleton height={60} width="30%" />
      </Stack>
    </Box>
  )
}

export const PersonalIfoSkeleton = () => {
  return (
    <Box width="100%">
      <Skeleton height={55} width="100%" />
      <Skeleton height={55} width="100%" />
      <Skeleton height={55} width="100%" />
      <Skeleton height={55} width="100%" />
      <Skeleton height={55} width="100%" />
      <Skeleton height={55} width="100%" />
      <Skeleton height={55} width="100%" />
      <Skeleton height={55} width="100%" />
    </Box>
  )
}
export const BalanceSkeleton = () => {
  return (
    <Stack
      gap={6}
      elevation={1}
      marginTop={4}
      paddingTop={4}
      component={Paper}
      justifyContent="space-between"
      direction={{ xs: 'column', lg: 'row' }}
    >
      <Box width={{ xs: '100%', lg: '50%' }}>
        <Skeleton height={55} width="100%" />
        <Skeleton height={200} width="100%" />
      </Box>
      <Box width={{ xs: '100%', lg: '50%' }}>
        <Skeleton height={80} width="100%" />
        <Skeleton height={80} width="100%" />
        <Skeleton height={80} width="100%" />
      </Box>
    </Stack>
  )
}
export const PaymentSkeleton = () => {
  const cards = Array.from(Array(4).keys())

  return (
    <Box
      gap={4}
      paddingTop={6}
      display="grid"
      gridAutoRows="auto"
      gridTemplateColumns="repeat(auto-fill, minmax(min(100%, 21rem), 1fr));"
    >
      {cards.map(item => (
        <Stack
          key={item}
          height={150}
          paddingY={5}
          elevation={1}
          component={Paper}
          justifyContent="space-between"
        >
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Skeleton height={40} width="200px" />
            <Skeleton height={40} width="40px" />
          </Stack>
          <Skeleton height={70} width="60%" />
          <Skeleton height={70} width="60%" />
          <Skeleton height={70} width="60%" />
        </Stack>
      ))}
    </Box>
  )
}

export const FundSkeleton = () => {
  return (
    <Box width="100%">
      <Skeleton height={200} width="100%" />
      <Skeleton height={80} width="100%" />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Skeleton height={55} width="40%" />
        <Skeleton height={55} width="20%" />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Skeleton height={55} width="60%" />
        <Skeleton height={55} width="20%" />
      </Stack>
      <Stack
        gap={6}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton height={80} width="50%" sx={{ borderRadius: 10 }} />
        <Skeleton height={80} width="50%" sx={{ borderRadius: 10 }} />
      </Stack>
    </Box>
  )
}
