import { useState } from 'react'
import { hookstate, useHookstate } from '@hookstate/core'
import { GetJobCounters, GetJobDetails } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useNavigation from 'hooks/useNavigation'
import { useQuery } from 'react-query'
import type { Counter, JobDetail } from 'types/jobs'
import { get } from 'utils'

const initialState = hookstate({
  details: {} as JobDetail
})
export default function useFetchDetail() {
  const { onNotify } = useNotification()
  const { details } = useHookstate(initialState)
  const [counters, setCounters] = useState({} as Counter)
  const [selectedBonus, setSelectedBonus] = useState(0)
  const { params } = useNavigation()

  const { isLoading: loadingCounters } = useQuery(
    'COUNTERS',
    () => GetJobCounters(Number(params.id)),
    {
      enabled: Boolean(params.id),
      onSuccess: data => {
        setCounters(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const { isLoading, refetch, isFetching } = useQuery(
    'JOB_DETAIL',
    () => GetJobDetails(Number(params.id)),
    {
      enabled: Boolean(params.id),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        details.set(data.data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  const onGetDetails = () => refetch()
  return {
    isLoading: isLoading || isFetching,
    details: details.value,
    counters: counters || ({} as Counter),
    selectedBonus,
    setSelectedBonus,
    isFetching,
    loadingCounters,
    refetch,
    onGetDetails
  }
}
