import { LoadingButton } from '@mui/lab'
import { Button, Typography, Box, Stack } from '@mui/material'
import useAddBonusConfig from 'app/bonus/templates/hooks/useAddBonusConfig'
import useAddBonus from 'app/bonus/templates/hooks/useAddBonusTemplate'
import useDeleteBonusConfig from 'app/bonus/templates/hooks/useDeleteBonusConfig'
import useUpdateBonusConfig from 'app/bonus/templates/hooks/useUpdateBonusConfig'
import useUpdateBonus from 'app/bonus/templates/hooks/useUpdateBonusTemplate'
import useTangoCompany from 'app/profile/myCompany/credits/hooks/useTangoCompany'
import Paper from 'components/paper'
import useStepper from 'components/stepper/useStepper'
import useAuth from 'hooks/useAuth'
import useNavigation from 'hooks/useNavigation'
import { Link } from 'react-router-dom'
import { COLORS } from 'styles/colors'
import type {
  BonusTemplateDetails,
  NewBonusTemplate,
  JobUpdateBonusTemplate
} from 'types/bonusTemplate'
import { UpdateBonusTemplateParams } from 'types/bonusTemplate'
import { formatCurrency, formatDate } from 'utils'
import { isEmpty } from 'utils/index'
import useTemplate from '../../useTemplate'

export default function ValidateStep({
  values,
  hasBonus,
  jobDetail
}: JobUpdateBonusTemplate) {
  const { params } = useNavigation()
  const { onToggleTemplate } = useTemplate()
  const { user } = useAuth()
  const { onBackStep } = useStepper()
  const { onAddBonusTemplate } = useAddBonus()
  const { onUpdateBonusTemplate } = useUpdateBonus()
  const { onUpdateBonusConfig } = useUpdateBonusConfig()
  const { onAddBonusConfig } = useAddBonusConfig()
  const { onDeleteBonusConfig } = useDeleteBonusConfig()
  const { tangoCompany, isLoading } = useTangoCompany()

  const [referralTotal, referrerTotal] = values.entries.bonus_config.reduce(
    (acc, { type, amount }) => [
      acc[0] + (type === 'referral' ? Number(amount) : 0),
      acc[1] + (type !== 'referral' ? Number(amount) : 0)
    ],
    [0, 0]
  )
  const validateIsForReferrer = () => {
    return (
      !isEmpty(
        values.entries.bonus_config.find(item => item.type === 'referrer') || {}
      ) && values.entries.is_for_referrer
    )
  }
  const validateIsForReferral = () => {
    return (
      !isEmpty(
        values.entries.bonus_config.find(item => item.type === 'referral') || {}
      ) && values.entries.is_for_referral
    )
  }
  const onAddBonus = async (values: BonusTemplateDetails) => {
    for (const element of values.entries.bonus_config) {
      delete element.edited
      delete element.id
      delete element.new
      element.amount = Number(element.amount)
      element.days = Number(element.days)
    }
    const data: NewBonusTemplate = {
      bonus_config: values.entries.bonus_config,
      job_id: Number(params.id),
      is_template: values.entries.is_template,
      status: values.entries.status,
      company_id: values.entries.company_id,
      description: values.entries.description,
      is_for_referral: values.entries.is_for_referral,
      is_for_referrer: values.entries.is_for_referrer,
      total_amount_referral: values.entries.total_amount_referral,
      total_amount_referrer: values.entries.total_amount_referrer,
      type: values.entries.type,
      user_id: values.entries.user_id
    }
    onAddBonusTemplate(data)
  }
  const onUpdateBonus = async (values: BonusTemplateDetails) => {
    const bonusTemplate = values.entries
    const updatedBonusTemplate: UpdateBonusTemplateParams = {
      is_template: false,
      is_for_referrer: bonusTemplate.is_for_referrer,
      is_for_referral: bonusTemplate.is_for_referral,
      description: bonusTemplate.description,
      total_amount_referral: Number(bonusTemplate.total_amount_referral),
      total_amount_referrer: Number(bonusTemplate.total_amount_referrer),
      type: 'flat rate'
    }
    onUpdateBonusTemplate(updatedBonusTemplate, Number(bonusTemplate.id))
    updateBonusConfig(values)
    addBonusConfig(values)
    deleteBonusConfig(values)
    onToggleTemplate('template')
  }
  const addBonusConfig = async (values: BonusTemplateDetails) => {
    const bonusTemplate = values.entries
    for (let i = 0; i < bonusTemplate.bonus_config.length; i++) {
      if (
        bonusTemplate.bonus_config[i].new &&
        !bonusTemplate.bonus_config[i].deleted
      ) {
        onAddBonusConfig(
          bonusTemplate.bonus_config[i],
          Number(bonusTemplate.id)
        )
      }
    }
  }
  const updateBonusConfig = async (values: BonusTemplateDetails) => {
    const bonusTemplate = values.entries
    for (let i = 0; i < bonusTemplate.bonus_config.length; i++) {
      if (
        bonusTemplate.bonus_config[i].edited &&
        !bonusTemplate.bonus_config[i].deleted
      ) {
        onUpdateBonusConfig(
          bonusTemplate.bonus_config[i],
          Number(bonusTemplate.id),
          Number(bonusTemplate.bonus_config[i].id)
        )
      }
    }
  }
  const deleteBonusConfig = async (values: BonusTemplateDetails) => {
    for (let i = 0; i < values.entries.bonus_config.length; i++) {
      if (
        values.entries.bonus_config[i].deleted &&
        !values.entries.bonus_config[i].new
      ) {
        onDeleteBonusConfig(
          Number(values.entries.id),
          Number(values.entries.bonus_config[i].id)
        )
      }
    }
  }
  const positions = Number(jobDetail?.open_positions)
  const totalEmployess = referrerTotal * positions || 0
  const totalReferral = referralTotal * positions || 0
  const totalBonus = totalEmployess + totalReferral
  const totalBalance = Number(tangoCompany?.beegopp_tango_data.tango_balance)
  const newBalance =
    Number(tangoCompany?.beegopp_tango_data.tango_balance) - Number(totalBonus)

  return (
    <Stack
      height="100%"
      justifyContent="space-between"
      padding="0 16px 16px 16px"
    >
      <Box component={Paper} elevation={4} sx={{ padding: '16px' }}>
        <Stack>
          <Typography variant="h3">General information</Typography>
          <Stack flexDirection="row" marginY={4}>
            <Stack sx={{ width: '40%' }}>
              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                Name
              </Typography>
              <Typography marginBottom={2} variant="body2" color={COLORS.gray2}>
                Creation Date
              </Typography>
              <Typography variant="body2" color={COLORS.gray2}>
                User create
              </Typography>
            </Stack>
            <Stack>
              <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                {values.entries.description}
              </Typography>
              <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                {formatDate(new Date() || '', "LLL do', 'yy")}
              </Typography>
              <Typography variant="h5" color={COLORS.black}>
                {user.first_name}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {validateIsForReferrer() && (
          <Stack>
            <Typography variant="body2">Bonus for employee</Typography>
            <Stack flexDirection="row" marginY={2}>
              <Stack sx={{ width: '40%' }}>
                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                >
                  Bonus type
                </Typography>
                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                >
                  User type
                </Typography>
                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                >
                  Total amount
                </Typography>

                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                >
                  Conditions
                </Typography>
              </Stack>
              <Stack>
                <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                  Flat rate
                </Typography>
                <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                  Employees
                </Typography>
                <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                  ${referrerTotal} USD
                </Typography>

                <Stack>
                  {values.entries.bonus_config.map((item, index) => {
                    if (item.type === 'referrer') {
                      return (
                        <Typography
                          key={index}
                          marginBottom={2}
                          variant="h5"
                          color={COLORS.black}
                        >
                          {`$${item.amount} USD after ${item.days} days`}
                        </Typography>
                      )
                    }
                  })}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        {validateIsForReferral() && (
          <Stack>
            <Typography variant="body2">Bonus for referral</Typography>
            <Stack flexDirection="row" marginY={2}>
              <Stack sx={{ width: '40%' }}>
                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                >
                  Bonus type
                </Typography>
                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                >
                  User type
                </Typography>
                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                >
                  Total amount
                </Typography>

                <Typography
                  marginBottom={2}
                  variant="body2"
                  color={COLORS.gray2}
                >
                  Conditions
                </Typography>
              </Stack>
              <Stack>
                <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                  Flate rate
                </Typography>
                <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                  Referral
                </Typography>
                <Typography marginBottom={2} variant="h5" color={COLORS.black}>
                  ${referralTotal} USD
                </Typography>

                <Stack>
                  {values.entries.bonus_config.map((item, index) => {
                    if (item.type === 'referral') {
                      return (
                        <Typography
                          key={index}
                          marginBottom={2}
                          variant="h5"
                          color={COLORS.black}
                        >
                          {`$${item.amount} USD after ${item.days} days`}
                        </Typography>
                      )
                    }
                  })}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        {isLoading ? (
          <span>loading...</span>
        ) : (
          <>
            <Typography variant="body2">Payment information</Typography>
            <Stack direction="row" justifyContent="space-between" marginTop={2}>
              <Typography
                marginBottom={2}
                variant="body2"
                color={COLORS.gray2}
                width="40%"
              >
                Current balance
              </Typography>
              <Typography
                marginBottom={2}
                variant="h5"
                color={COLORS.main}
                width="60%"
              >
                {formatCurrency(
                  Number(tangoCompany?.beegopp_tango_data.tango_balance) || 0
                )}{' '}
                USD
              </Typography>
            </Stack>
            {validateIsForReferrer() && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    marginBottom={2}
                    marginTop={2}
                    variant="body2"
                    color={COLORS.gray2}
                    width="40%"
                  >
                    Employees
                  </Typography>
                  <Typography
                    marginBottom={2}
                    marginTop={2}
                    variant="h5"
                    color={COLORS.black}
                    width="60%"
                  >
                    {jobDetail?.open_positions} Job positions X {referrerTotal}{' '}
                    USD
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    marginBottom={2}
                    variant="body2"
                    color={COLORS.gray2}
                    width="40%"
                  >
                    Total for employees
                  </Typography>
                  <Typography
                    marginBottom={2}
                    variant="h5"
                    color={COLORS.black}
                    width="60%"
                  >
                    {formatCurrency(totalEmployess)} USD
                  </Typography>
                </Stack>
              </>
            )}
            {validateIsForReferral() && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    marginBottom={2}
                    marginTop={2}
                    variant="body2"
                    color={COLORS.gray2}
                    width="40%"
                  >
                    Referrals
                  </Typography>
                  <Typography
                    marginBottom={2}
                    marginTop={2}
                    variant="h5"
                    color={COLORS.black}
                    width="60%"
                  >
                    positions {jobDetail?.open_positions} X {referralTotal} USD
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    marginBottom={2}
                    variant="body2"
                    color={COLORS.gray2}
                    width="40%"
                  >
                    Total for referrals
                  </Typography>
                  <Typography
                    marginBottom={2}
                    variant="h5"
                    color={COLORS.black}
                    width="60%"
                  >
                    {formatCurrency(totalReferral)} USD
                  </Typography>
                </Stack>
              </>
            )}
            {(totalEmployess > 0 || totalReferral > 0) && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    marginBottom={2}
                    marginTop={2}
                    variant="body2"
                    color={COLORS.gray2}
                    width="40%"
                  >
                    Total to pay
                  </Typography>
                  <Typography
                    marginBottom={2}
                    marginTop={2}
                    variant="h5"
                    color={COLORS.black}
                    width="60%"
                    fontWeight={700}
                  >
                    {formatCurrency(totalBonus)} USD
                  </Typography>
                </Stack>
                {newBalance > 0 && (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      marginBottom={2}
                      variant="body2"
                      color={COLORS.gray2}
                      width="40%"
                    >
                      New balance
                    </Typography>
                    <Typography
                      marginBottom={2}
                      variant="h5"
                      color={COLORS.black}
                      width="60%"
                      fontWeight={700}
                    >
                      {formatCurrency(newBalance)} USD
                    </Typography>
                  </Stack>
                )}
              </>
            )}

            {totalBonus > totalBalance && (
              <Typography
                variant="h6"
                color={COLORS.red1}
                fontSize={14}
                marginY={2}
              >
                To continue you need{' '}
                <Link to="/profile/company">Fund your account</Link>
              </Typography>
            )}
            {totalBalance > totalBonus && (
              <Typography
                variant="h6"
                color={COLORS.red1}
                fontSize={14}
                marginY={2}
              >
                You are going to pay{' '}
                <strong>{formatCurrency(totalBonus)} </strong> USD dollars
              </Typography>
            )}
          </>
        )}
      </Box>
      <Stack marginTop={2} alignItems="center">
        {hasBonus && (
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            onClick={() => onUpdateBonus(values)}
          >
            Save bonus
          </LoadingButton>
        )}
        {!hasBonus && (
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            loading={isLoading}
            disabled={isLoading || totalBalance <= 0}
            onClick={() => onAddBonus(values)}
          >
            Create bonus
          </LoadingButton>
        )}
        <Button onClick={onBackStep}>
          <Typography
            variant="body2"
            marginLeft={2}
            marginRight={2}
            color={COLORS.main}
          >
            Back
          </Typography>
        </Button>
      </Stack>
    </Stack>
  )
}
