import DisplaySettings from '@mui/icons-material/DisplaySettings'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Stack, Typography, Chip } from '@mui/material'

import Paper from 'components/paper'
import { IntegrationSkeleton } from 'components/skeleton'
import ToggleSidebar from 'components/toggleSidebar'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'
import Connect from './connect'
import Disconnect from './disconnect'
import useDisconnect from './hooks/useDisconnect'
import useConfigDetail from './settings/hooks/useConfigDetail'

export default function Integrations() {
  const { onNavigate } = useNavigation()
  const { isEnable, isLoading: isLoadingConfig } = useConfigDetail()

  const {
    onConnect,
    onCloseSidebar,
    onOpenDialog,
    isLoading,
    openSidebar,
    hasConfigId
  } = useDisconnect()

  const handleRedirect = () => {
    onNavigate('settings')
  }

  return (
    <Box>
      <Stack flexDirection="row" margin="8px 24px" alignItems="center">
        <DisplaySettings />
        <Typography variant="h3" padding="8px 16px">
          Integration
        </Typography>
      </Stack>
      {isLoadingConfig ? (
        <IntegrationSkeleton />
      ) : (
        <Box
          component={Paper}
          bgcolor={COLORS.white}
          width={{ xs: 'auto', sm: '380px' }}
        >
          {hasConfigId && isEnable ? (
            <Stack flexDirection="column" justifyContent="space-around">
              <Box display="flex" justifyContent="flex-end">
                <Chip label="Connected" color="success" />
              </Box>
              <Stack flexDirection="row">
                <Stack width="130px" marginRight="10%">
                  <img src="/images/toroBegopp.png" alt="toro" width="100%" />
                </Stack>
                <Stack alignSelf="center">
                  <Typography fontSize="20px" fontWeight="500">
                    Bullhorn
                  </Typography>
                  <Typography variant="body2" color={COLORS.gray2}>
                    ATS integration
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack flexDirection="row" justifyContent="space-around">
              <Stack width="130px">
                <img src="/images/toroBegopp.png" alt="toro" width="100%" />
              </Stack>
              <Stack alignSelf="center">
                <Typography fontSize="20px" fontWeight="500">
                  Bullhorn
                </Typography>
                <Typography variant="body2" color={COLORS.gray2}>
                  ATS integration
                </Typography>
              </Stack>
            </Stack>
          )}
          {hasConfigId && isEnable ? (
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              marginTop={3}
            >
              <Button variant="outlined" onClick={handleRedirect}>
                Settings
              </Button>
              <Button variant="honey" onClick={onOpenDialog}>
                Disconnect
              </Button>
            </Stack>
          ) : (
            <LoadingButton
              fullWidth
              disabled={isLoading}
              loading={isLoading}
              onClick={() => onConnect(isEnable)}
              variant="contained"
              sx={{ marginTop: 3 }}
            >
              {hasConfigId ? 'Connect' : 'Add Credencials'}
            </LoadingButton>
          )}

          <Disconnect />

          <ToggleSidebar
            isOpen={openSidebar}
            headerTitle="Connect with Bullhorn"
            toggleSidebar={onCloseSidebar}
          >
            <Connect />
          </ToggleSidebar>
        </Box>
      )}
    </Box>
  )
}
