import { hookstate, useHookstate } from '@hookstate/core'
import { AddBonusTemplate } from 'api'
import useTemplate from 'app/educationDetails/sideDetail/bonus/useTemplate'
import useJobStatus from 'app/jobs/hooks/useJobStatus'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { NewBonusTemplate } from 'types/bonusTemplate'
import { get } from 'utils'
import useBonusTable from './useBonusTable'
const initialState = hookstate({
  isSent: false,
  isLoading: false
})

interface AddBonusTemplateParameters {
  data: NewBonusTemplate
}

export default function useAddBonusTemplate() {
  const { onSubmit } = useJobStatus()
  const { isSent, isLoading } = useHookstate(initialState)
  const { onNotify } = useNotification()
  const { getBonusTemplates } = useBonusTable()
  const { onToggleTemplate } = useTemplate()
  const { user } = useAuth()
  const query = useQueryClient()

  const { mutate } = useMutation(
    (data: AddBonusTemplateParameters) => AddBonusTemplate(data.data),
    {
      onSuccess: () => {
        query.invalidateQueries('JOB_DETAIL')
        onNotify({
          severity: 'success',
          message: 'Bonus template added successfully'
        })
        getBonusTemplates()
        onSubmit('published')
        onToggleTemplate('template')
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        isLoading.set(false)
      }
    }
  )

  const onAddBonusTemplate = (newBonusTemplate: NewBonusTemplate) => {
    newBonusTemplate.bonus_config.map(item => {
      item.amount = Number(item.amount)
      item.days = Number(item.days)
    })
    newBonusTemplate.company_id = user.company_id
    newBonusTemplate.user_id = user.id
    mutate({ data: newBonusTemplate })
  }

  return {
    onAddBonusTemplate,
    isLoading: isLoading.value,
    isSent: isSent.value,
    setIsSent: isSent.set
  }
}
