import { GridSelectionModel } from '@mui/x-data-grid'
import { ResendCompanyInvite } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { get } from 'utils'

type ActionType = 'resend' | 'delete'
export default function useResendDelete(callback: () => void) {
  const { onNotify } = useNotification()

  const { isLoading, mutate } = useMutation(
    (data: { companies: GridSelectionModel }) => ResendCompanyInvite(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Invitation sent successfully'
        })
        callback()
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail[0].errors')
        const errorMessageId = get(error, 'response.data.errors.detail[0].id')
        onNotify({
          severity: 'error',
          message: JSON.stringify(`company-${errorMessageId}: ${errorMessage}`)
        })
      }
    }
  )

  const onSubmit = ({
    companies,
    actionType
  }: {
    companies: GridSelectionModel
    actionType: ActionType
  }) => {
    console.log('>>>>>>>>>>>', actionType)
    mutate({ companies })
  }

  return { isLoading, onSubmit }
}
