import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import TextField from 'components/TextField'
import Paper from 'components/paper'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import useConnect, {
  initialValues,
  validationSchema
} from '../hooks/useConnect'

export default function Connet() {
  const { onSubmit, showField, onShowField, onCloseSidebar } = useConnect()

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <Stack
          component={Form}
          padding={2}
          height={'calc(100vh - 130px)'}
          justifyContent="space-between"
          sx={{
            overflow: 'auto',
            ...StylesScrollBar
          }}
        >
          <Box component={Paper} marginY={2} paddingX={3}>
            <Typography variant="h3" marginBottom={2}>
              Credentials
            </Typography>
            <Typography variant="h5" color={COLORS.gray2} marginBottom={2}>
              To connect Beegopp we need this data
            </Typography>
            <Box paddingX={2}>
              <TextField
                fullWidth
                name="clientId"
                variant="standard"
                label="Client ID"
                sx={{ marginBottom: 2 }}
                type={showField.clientId ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => onShowField('clientId')}
                      edge="end"
                    >
                      {showField.clientId ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  )
                }}
              />
              <TextField
                fullWidth
                name="clientSecret"
                variant="standard"
                label="Client secret"
                sx={{ marginBottom: 2 }}
                type={showField.clientSecret ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => onShowField('clientSecret')}
                      edge="end"
                    >
                      {showField.clientSecret ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  )
                }}
              />
              <TextField
                fullWidth
                name="username"
                variant="standard"
                label="User name"
                sx={{ marginBottom: 2 }}
                type={showField.username ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => onShowField('username')}
                      edge="end"
                    >
                      {showField.clientSecret ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  )
                }}
              />
              <TextField
                fullWidth
                name="password"
                variant="standard"
                label="Password"
                sx={{ marginBottom: 2 }}
                type={showField.password ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => onShowField('password')}
                      edge="end"
                    >
                      {showField.password ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  )
                }}
              />
            </Box>
          </Box>
          <Stack marginTop={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ marginBottom: '10px' }}
            >
              Continue
            </LoadingButton>
            <Button variant="text" onClick={onCloseSidebar}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  )
}
