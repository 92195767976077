import { useEffect, useState } from 'react'
import { GetFunds } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useQuery } from 'react-query'
import { PaymentMethodResponse } from 'types/profile'
import { get, getPagination } from 'utils'

export default function useFunds() {
  const { onNotify } = useNotification()
  const [funds, setFunds] = useState<PaymentMethodResponse>(
    {} as PaymentMethodResponse
  )

  const { isLoading, isFetching, refetch } = useQuery(
    'FUNDS',
    () => GetFunds(),
    {
      onSuccess: data => {
        setFunds(data)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  useEffect(() => {
    refetch()
  }, [])

  const pagination = getPagination(funds)

  return {
    isLoading: isLoading || isFetching,
    funds: funds.entries || [],
    pagination
  }
}
