import { Fragment, useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Button, Stack, Typography } from '@mui/material'

import EmptySettings from 'app/profile/integrations/settings/emptySettings'
import Breadcrumbs from 'components/breadcrums'
import Paper from 'components/paper'
import { Configurationkeleton } from 'components/skeleton'
import ToggleSidebar from 'components/toggleSidebar'
import { COLORS } from 'styles/colors'
import type { EntityName } from 'types/profile'
import type { Field } from 'types/profile'
import { getSettingsInformation } from 'utils/catalogs'
import CandidatesSettings from './candidatesSettings'
import EmployeeSetting from './employeeSettings'
import useConfigDetail from './hooks/useConfigDetail'
import useSettings from './hooks/useSettings'
import JobsSettings from './jobsSettings'

const updateComponent = {
  employee: <EmployeeSetting />,
  job: <JobsSettings />,
  candidate: <CandidatesSettings />
}

export default function Settings() {
  const [selectedType, setSelectedType] = useState<EntityName>('employee')

  const { openConfig, onToggleConfig, onSelectedField } = useSettings()
  const { isLoading, hasMappedFields, mappedFields } = useConfigDetail()

  const handleEdit = ({ field, type }: { field: Field; type: EntityName }) => {
    onSelectedField(field)
    setSelectedType(type)
    onToggleConfig('editConfig')
  }

  return (
    <Box paddingBottom={6}>
      <Stack>
        <Stack flexDirection="row" alignItems="center" padding="24px 0 10px">
          <SettingsIcon
            sx={{ fontSize: '25px', paddingRight: '15px' }}
            htmlColor={COLORS.black1}
          />
          <Typography variant="h2">Configuration</Typography>
        </Stack>
        <Box>
          <Breadcrumbs
            routes={[{ route: '/profile/integrations', label: 'ATS Setting' }]}
          >
            Configuration
          </Breadcrumbs>
        </Box>
      </Stack>
      {isLoading ? (
        <Configurationkeleton />
      ) : (
        <Fragment>
          {hasMappedFields ? (
            <Stack gap={4} direction="row" flexWrap="wrap">
              {getSettingsInformation(mappedFields).map(item => (
                <Stack
                  key={item.id}
                  component={Paper}
                  paddingBottom={3}
                  width={{ xs: '100%', sm: '40%', lg: '44%' }}
                >
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h3">{item.title}</Typography>
                    <Button
                      startIcon={<EditIcon htmlColor={COLORS.main} />}
                      onClick={() =>
                        handleEdit({
                          field: item.fields,
                          type: item.type as EntityName
                        })
                      }
                    >
                      Edit
                    </Button>
                  </Stack>
                  <Typography
                    variant="subtitle1"
                    color={COLORS.blue1}
                    padding="16px 0 10px"
                  >
                    {item.subTitle}
                  </Typography>
                  <Stack
                    flexDirection="row"
                    padding="16px 0"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body2"
                      color={COLORS.gray2}
                      width="50%"
                    >
                      {item.begopp}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={COLORS.gray2}
                      width="50%"
                    >
                      {item.bulhorn}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column">
                    {item.fields.map((item, index) => (
                      <Stack
                        key={index}
                        width="100%"
                        direction="row"
                        paddingBottom={2}
                        alignItems="baseline"
                        justifyContent="space-between"
                      >
                        <Stack
                          flexDirection="row"
                          width="50%"
                          justifyContent="space-between"
                        >
                          <Typography
                            width="60%"
                            variant="h5"
                            textTransform="capitalize"
                          >
                            {item.label}
                          </Typography>
                          <ArrowForwardIcon
                            htmlColor={COLORS.honey4}
                            sx={{ width: '30%' }}
                          />
                        </Stack>
                        <Typography variant="h5" width="50%" noWrap>
                          {item.value}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          ) : (
            <EmptySettings />
          )}
        </Fragment>
      )}
      <ToggleSidebar
        minWidth={650}
        isOpen={openConfig.editConfig}
        toggleSidebar={() => onToggleConfig('editConfig')}
        headerTitle={`Edit ${selectedType} configuration`}
      >
        <Box padding={2}>{updateComponent[selectedType]}</Box>
      </ToggleSidebar>
    </Box>
  )
}
