import AddIcon from '@mui/icons-material/Add'
import CreateIcon from '@mui/icons-material/Create'
import {
  Box,
  BoxProps,
  Button,
  Chip,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import useFetchDetail from 'app/educationDetails/hooks/useFetchDetail'
import ToggleSidebar from 'components/toggleSidebar'
import { COLORS } from 'styles/colors'
import Visibility from '../Visibility'
import BonusChips from './bonusChips'
import Templates from './templates'
import useTemplate from './useTemplate'

interface BonusProps extends BoxProps {
  title: string
  subtitle: string
  type: 'bonus' | 'visibility' | 'bonusReferrals'
}

export default function Bonus({ ...props }: BonusProps) {
  const { openTemplate, onToggleTemplate } = useTemplate()
  const {
    details: { bonus, id }
  } = useFetchDetail()

  const hasBonus = bonus
  const handleEditBonus = () => {
    onToggleTemplate('template', { bonusId: bonus?.id })
  }
  const handleEditVisibility = () => {
    onToggleTemplate('visibility', { id: id })
  }
  const handleAddBonus = () => {
    onToggleTemplate('template')
  }
  return (
    <Box component={Paper} {...props}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography variant="h3" marginRight={2}>
            {props.title}
          </Typography>
          {props.type !== 'visibility' && (
            <Chip
              disabled={!hasBonus}
              variant="outlined"
              label={hasBonus ? 'Active' : 'Empty'}
              color={hasBonus ? 'primary' : 'other'}
              sx={{ bgcolor: 'transparent' }}
            />
          )}
        </Stack>
        {props.type === 'visibility' && (
          <Stack
            component="div"
            direction="row"
            alignItems="center"
            onClick={handleEditVisibility}
            color={hasBonus ? COLORS.main : COLORS.gray2}
            sx={{ cursor: 'pointer' }}
          >
            <CreateIcon sx={{ width: '16px' }} />
            <Typography variant="h6" marginLeft={1} fontSize="13px">
              Edit
            </Typography>
          </Stack>
        )}
        {hasBonus && props.type !== 'visibility' && (
          <Stack
            component="div"
            direction="row"
            alignItems="center"
            onClick={handleEditBonus}
            color={hasBonus ? COLORS.main : COLORS.gray2}
            sx={{ cursor: 'pointer' }}
          >
            <CreateIcon sx={{ width: '16px' }} />
            <Typography variant="h6" marginLeft={1} fontSize="13px">
              Edit
            </Typography>
          </Stack>
        )}
      </Stack>
      {hasBonus ? (
        <BonusChips type={props.type} />
      ) : (
        <Box marginTop={2}>
          <Typography variant="body2" color={COLORS.gray1} marginBottom={1}>
            {props.subtitle}
          </Typography>
        </Box>
      )}
      {props.type !== 'visibility' && !hasBonus && (
        <Button
          fullWidth
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddBonus}
        >
          Add Bonus
        </Button>
      )}
      <ToggleSidebar
        headerTitle="Set up a bonus"
        isOpen={openTemplate.template}
        toggleSidebar={() => onToggleTemplate('template')}
      >
        <Templates />
      </ToggleSidebar>
      <ToggleSidebar
        headerTitle="Job Configuration"
        isOpen={openTemplate.visibility}
        toggleSidebar={() => onToggleTemplate('visibility')}
      >
        <Visibility />
      </ToggleSidebar>
    </Box>
  )
}
