import { Box, Chip, Paper, Stack, Typography } from '@mui/material'
import Accordion from 'components/accordion'
import { COLORS } from 'styles/colors'
import { formatCurrency } from 'utils'
import useJobBoardDetail from '../hooks/useJobBoardDetail'

export default function Bonus() {
  const {
    details: { bonus }
  } = useJobBoardDetail()

  const bonusConfigByReferrer = bonus.bonus_config.filter(
    item => item.type === 'referrer'
  )

  const bonusConfigByReferral = bonus.bonus_config.filter(
    item => item.type === 'referral'
  )

  return (
    <Box component={Paper}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={3}
      >
        <Typography variant="h3" marginRight={2}>
          Bonus details
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" marginBottom={2}>
        <Typography variant="h6" color={COLORS.gray2} marginRight={2}>
          Bonus type
        </Typography>
        <Chip
          label={
            <Typography
              variant="body2"
              color={COLORS.gray1}
              textTransform="capitalize"
            >
              {bonus.type}
            </Typography>
          }
          variant="filled"
          sx={{
            height: 24,
            bgcolor: COLORS.honey2,
            fontSize: 13
          }}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" marginBottom={1}>
        <Typography color={COLORS.blue3} variant="h6">
          Conditions
        </Typography>
        <Typography
          variant="body2"
          color={COLORS.green3}
          alignSelf="flex-start"
        >
          Earn
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="row" alignItems="flex-end">
          <Typography variant="h6" color={COLORS.gray2} marginRight={2}>
            Bonus for
          </Typography>
          <Chip
            label="Employee"
            variant="filled"
            color="info"
            sx={{ height: 24, fontSize: 13, bgcolor: COLORS.blue6 }}
          />
        </Stack>
        <Accordion
          color={COLORS.green2}
          title={
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Typography
                fontSize={22}
                variant="body1"
                fontWeight={500}
                marginRight={1}
                color={COLORS.green2}
              >
                {formatCurrency(Number(bonus?.total_amount_referrer || 0))}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                variant="body1"
                color={COLORS.green2}
              >
                Total amount
              </Typography>
            </Stack>
          }
        >
          {bonusConfigByReferrer.map(item => (
            <Typography
              key={item.id}
              variant="body2"
              fontSize={12}
              textAlign="right"
              marginBottom={1}
              display={{ xs: 'none', lg: 'block' }}
            >
              {formatCurrency(Number(item.amount || 0))} After {item.days} days
            </Typography>
          ))}
        </Accordion>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="row" alignItems="flex-end">
          <Typography variant="h6" color={COLORS.gray2} marginRight={2}>
            Bonus for
          </Typography>
          <Chip
            color="info"
            label="Referral"
            variant="filled"
            sx={{ height: 24, fontSize: 13, bgcolor: '#FF8743' }}
          />
        </Stack>
        <Accordion
          color={COLORS.green2}
          title={
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Typography
                fontSize={22}
                variant="body1"
                fontWeight={500}
                marginRight={1}
                color={COLORS.green2}
              >
                {formatCurrency(Number(bonus?.total_amount_referral || 0))}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                variant="body1"
                color={COLORS.green2}
              >
                Total amount
              </Typography>
            </Stack>
          }
        >
          {bonusConfigByReferral.map(item => (
            <Typography
              key={item.id}
              variant="body2"
              marginBottom={1}
              fontSize={12}
              textAlign="right"
              display={{ xs: 'none', lg: 'block' }}
            >
              {formatCurrency(Number(item.amount || 0))} After {item.days} days
            </Typography>
          ))}
        </Accordion>
      </Stack>
    </Box>
  )
}
