import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Stack, Typography, TextField } from '@mui/material'

import Paper from 'components/paper'
import { InfoSkeleton } from 'components/skeleton'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { EntityList, Step } from 'types/profile'
import { clearEntities } from 'utils'
import useCompanyConfig from '../hooks/useCompanyConfig'
import useSettings from '../hooks/useSettings'

export default function EmployeeSetting() {
  const {
    onContinue,
    saving,
    isLoading,
    entity,
    companyEntity,
    selectedField
  } = useSettings('employee')

  const isUpdate = selectedField && selectedField.length > 0

  const { onUpdateStep, isLoading: sending } = useCompanyConfig(isUpdate)

  const validateEntities = clearEntities(entity)

  const updatedField: any = selectedField?.reduce((acc, current) => {
    const label = current.label.replace(/ /g, '_')
    return { ...acc, [label]: current }
  }, {})

  const initialValues = validateEntities.reduce((acc, current) => {
    return {
      ...acc,
      [current.value]:
        updatedField && updatedField[current.value]
          ? updatedField[current.value].value
          : ''
    }
  }, {} as { [key in EntityList]: string })

  const bullhornOptions =
    companyEntity?.fields.map(option => {
      return { name: option.name, label: option.name }
    }) || []

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => {
        if (isUpdate) {
          return onUpdateStep(values, 'employee')
        }
        onContinue({ step: Step.Employee, data: values })
      }}
    >
      {({ setFieldValue, values }) => (
        <Stack component={Form} justifyContent="space-between">
          <Stack marginBottom={3}>
            <Typography fontSize="14px" fontWeight="500" paddingTop={2}>
              Data field mapping
            </Typography>
            <Typography fontSize="14px" fontWeight="400" paddingTop={2}>
              Customize data fields by synced from Beegopp to bulhorn
            </Typography>
          </Stack>
          <Box component={Paper} marginBottom={3}>
            <Stack paddingY={2}>
              {isLoading ? (
                <InfoSkeleton />
              ) : (
                <>
                  <Stack paddingBottom={2}>
                    <Typography fontSize="14px" fontWeight="500">
                      Data field mapping
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row">
                    <Typography
                      fontSize="14px"
                      fontWeight="400"
                      width="50%"
                      color={COLORS.gray2}
                    >
                      Beegopp field
                    </Typography>
                    <Typography
                      fontSize="14px"
                      fontWeight="400"
                      width="50%"
                      color={COLORS.gray2}
                    >
                      Bullhorn field
                    </Typography>
                  </Stack>
                  <Stack flexDirection="column" paddingTop="20px">
                    {validateEntities.map((item, index) => (
                      <Box
                        key={index}
                        width="100%"
                        display="flex"
                        paddingBottom={2}
                        flexDirection="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                      >
                        <Stack
                          flexDirection="row"
                          width="50%"
                          justifyContent="flex-start"
                        >
                          <Typography
                            width="50%"
                            variant="h5"
                            textTransform="capitalize"
                          >
                            {item.label}
                          </Typography>
                          <ArrowForwardIcon
                            htmlColor={COLORS.honey4}
                            sx={{ width: '50%' }}
                          />
                        </Stack>
                        <Autocomplete
                          disablePortal
                          options={bullhornOptions}
                          getOptionLabel={option => option.label || ''}
                          value={{
                            name: values[item.value as EntityList],
                            label: values[item.value as EntityList]
                          }}
                          onChange={(_, option) => {
                            setFieldValue(item.value, option?.name || '')
                          }}
                          sx={{ width: '50%' }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Search value"
                              name={item.value}
                              variant="standard"
                            />
                          )}
                        />
                      </Box>
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          </Box>
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            loading={saving || sending}
            disabled={isLoading || saving || sending}
            sx={{
              marginBottom: 2
            }}
          >
            Continue
          </LoadingButton>
        </Stack>
      )}
    </Formik>
  )
}
