export const listEmployees = (companyId: number) =>
  `/companies/${companyId}/employees`

export const listReferralsByEmployee = (companyId: number, userId: number) =>
  `/companies/${companyId}/referrals/employee/${userId}`

export const baseBonus = (companyId: number) => `/companies/${companyId}/bonus`

export const bonusPayments = (companyId: number) =>
  `/companies/${companyId}/payments`

export const listReferrals = (companyId: number) =>
  `/companies/${companyId}/referrals`

export const getReferral = (id: number, companyId: number) =>
  `/companies/${companyId}/referrals/${id}`

export const reportPayments = (companyId: number) =>
  `/companies/${companyId}/payments/report/download`

export const listJobs = (companyId: number) => `/companies/${companyId}/jobs`

export const listCompanies = '/admin/companies'

export const resendCompaniesInvite = '/admin/companies/invites/resent'

export const resendEmployeeInvite = (companyId: number) =>
  `/companies/${companyId}/employees/invites/resent`

export const login = `/auth/login`

export const register = `/public/companies`

export const loginAsCompany = `/auth/login`

export const signUp = `/auth/signup`

export const codeSms = `/auth/signup/validate/nip`

export const codeEmail = `/auth/signup/validate/nip`

export const resetPassword = `/auth/reset_password`

export const bulkUpdateBonusTemplates = (companyId: number) =>
  `/companies/${companyId}/bonus/bulk`
//actualizar
export const bulkUpdatePaymentStatus = (companyId: number) =>
  `/companies/${companyId}/payments/manage`

export const beegoppEntities = (companyId: number) =>
  `/companies/${companyId}/entities/internal`

export const referralByEmailSms = (companyId: number) =>
  `/companies/${companyId}/jobs/notification`

export const requestbyWithdraw = (employeeId: number, companyId: number) =>
  `/companies/${companyId}/users/${employeeId}/wallet/payments`

export const getBonusPaymentsWallet = (
  id: number | string | undefined,
  companyId: number
) => `/companies/${companyId}/users/${id}/wallet/payments`

export const jobStatus = (companyId: number) =>
  `/companies/${companyId}/jobs/status`

export const jobDelete = (companyId: number) =>
  `/companies/${companyId}/jobs/status`

export const jobVisibility = (jobId: number, companyId: number) =>
  `/companies/${companyId}/jobs/${jobId}`

export const getUser = (userId: number) => `/users/${userId}`

export const updateUser = (userId: number) => `/users/${userId}`

export const createEmployees = (companyId: number) =>
  `/companies/${companyId}/employees`

export const updateEmployee = (employeeId: number, companyId: number) =>
  `/companies/${companyId}/employees/${employeeId}`

export const countByEmployeeId = (
  employeeId: string | number,
  companyId: number
) => `/companies/${companyId}/users/${employeeId}/wallet`

export const readyToPay = (employeeId: string | number, companyId: number) =>
  `/companies/${companyId}/users/${employeeId}/wallet/payments/ready_to_pay`

export const dashReferral = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/admin/dashboard/referrals/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`
export const dashReferralE = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/companies/${companyId}/dashboard/referrals/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`
export const dashJobs = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/admin/dashboard/jobs/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`
export const dashJobsE = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/companies/${companyId}/dashboard/jobs/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`
export const dashPayments = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/admin/dashboard/payments/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`
export const dashPaymentsE = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/companies/${companyId}/dashboard/payments/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`

export const dashBonus = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/admin/dashboard/bonus/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`
export const dashReferralFunnel = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/admin/dashboard/referrals_funnel/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`
export const dashReferralFunnelE = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/companies/${companyId}/dashboard/referrals_funnel/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`

export const dashOverview = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/admin/dashboard/overview/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`
export const dashOverviewE = (
  employeeId: string | number,
  companyId: number,
  initialDate: Date | string,
  endDate: Date | string
) =>
  `/companies/${companyId}/dashboard/overview/?init_date=${initialDate} 00:00:00&end_date=${endDate} 00:00:00`

export const countByUserId = (employeeId: string | number, companyId: number) =>
  `/companies/${companyId}/employees/${employeeId}/wallet`

export const referralByJobId = (jobId: number, companyId: number) =>
  `/companies/${companyId}/jobs/${jobId}/referrals`

export const changePassword = (userId: number) =>
  `/users/change-password/${userId}`

export const bonusTemplatesByCompany = (companyId: number) =>
  `/companies/${companyId}/bonus`

export const paymentById = (paymentId: number, companyId: number) =>
  `/companies/${companyId}/payments/${paymentId}`

export const publicJobsByCompany = (companyId: number) =>
  `/public/companies/${companyId}/jobs`

export const newJobApplication = (companyId: number, jobId: number) =>
  `/public/companies/${companyId}/jobs/${jobId}/candidate`

export const deleteBonusConfig = ({
  bonusId,
  configId,
  companyId
}: {
  bonusId: number
  configId: number
  companyId: number
}) => `/companies/${companyId}/bonus/${bonusId}/configs/${configId}`

//pendiente
export const importJobs = (companyId: number) =>
  `/companies/${companyId}/external/jobs/import`

export const jobsByCompany = (companyId: number) =>
  `/companies/${companyId}/external/jobs`

export const publicJobDetail = (companyId: number, jobId: number) =>
  `/public/companies/${companyId}/jobs/${jobId}`

export const jobDetail = (jobId: number, companyId: number) =>
  `/companies/${companyId}/jobs/${jobId}`

export const jobCounters = (jobId: number) => `/jobs/${jobId}/dashboard`

export const companyDetail = (companyId: number) =>
  `/admin/companies/${companyId}`

export const companyProfile = (companyId: number) => `/companies/${companyId}`

export const configuration = (companyId: number) =>
  `/companies/${companyId}/configurations`

export const companyEntities = (companyId: number) =>
  `/companies/${companyId}/entities/external`

export const updateConfiguration = (companyId: number, configId: number) =>
  `/companies/${companyId}/configurations/${configId}`

export const toggleConfig = (companyId: number, configId: number) =>
  `/companies/${companyId}/configurations/${configId}/toggle`

export const comapnyConfigDetail = (companyId: number, configId: number) =>
  `/companies/${companyId}/configurations/${configId}`

export const listCompaniesByFilterJobs = (companyId: number) =>
  `/companies/${companyId}/jobs/companies`

export const jobsTypes = (companyId: number) =>
  `/companies/${companyId}/jobs/types`

export const jobCounterViews = (companyId: number, jobId: number) =>
  `/public/companies/${companyId}/jobs/${jobId}/views`

export const sendNotificationToken = (userId: number) =>
  `/users/${userId}/notifications/token`

export const userNotifications = (userId: number) =>
  `/users/${userId}/notifications`

export const viewedUserNotification = (
  userId: number,
  notificationId: number
) => `/users/${userId}/notifications/${notificationId}/viewed`

export const addPaymentMethod = (companyId: number) =>
  `/companies/${companyId}/tango/credit_card`

export const rechargeCreditCard = (companyId: number) =>
  `/companies/${companyId}/tango/fund`

export const activePaymentMethod = (companyId: number, cardId: number) =>
  `/companies/${companyId}/tango/credit_card/${cardId}`

export const unRegisterCard = (companyId: number, cardId: number) =>
  `/companies/${companyId}/tango/credit_card/${cardId}/unregister`

export const tangoCompany = (companyId: number) =>
  `/companies/${companyId}/tango/company_info`

export const paymentMethods = (companyId: number) =>
  `/companies/${companyId}/tango/credit_cards`

export const transactions = (companyId: number) =>
  `/companies/${companyId}/tango/transactions`

export const getFunds = (companyId: number) =>
  `/companies/${companyId}/tango/funds`
