import { Box } from '@mui/material'
import BulkActions from 'components/bulkactions'
import { EmptyPage } from 'components/emtpyPage/'
import { DataGrid, Pagination } from 'components/table'
import ToggleSidebar from 'components/toggleSidebar/'
import useNavigation from 'hooks/useNavigation'
import { usePage } from 'hooks/usePage'
import { commonTableStyles } from 'styles/utils'
import { columns } from './constants'
import useReferralTable from './hooks/useReferralTable'
import ReferralDetail from './referralDetail'
const ReferralsTable = () => {
  const { onNavigate } = useNavigation()
  const {
    onToggleTemplate,
    isLoading,
    openReReferralDetail,
    referrals,
    pagination
  } = useReferralTable()
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePage()
  return (
    <Box
      width="100%"
      height={commonTableStyles.minHeight}
      paddingBottom={3}
      marginTop={4}
    >
      <DataGrid
        rows={referrals}
        columns={columns}
        checkboxSelection
        paginationMode="server"
        rowCount={pagination.totalPages || 0}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        loading={isLoading}
        onCellClick={props => {
          if (props.field === 'job_id') {
            return onNavigate(`/jobs/boardconfig/${props.row.job_id}`)
          }
          if (props.field !== '__check__') {
            return onToggleTemplate('referrals', { id: props.row.referral_id })
          }
        }}
        components={{
          Pagination: () => (
            <Pagination
              page={page}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalPages}
            />
          ),
          Header: () => (
            <BulkActions
              hideBulk
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              totalPages={pagination.totalEntries}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <></>
            </BulkActions>
          ),
          NoResultsOverlay: () => (
            <EmptyPage
              message="You do not have registered referrals yet"
              logo="/images/referralblank.svg"
            >
              <span></span>
            </EmptyPage>
          )
        }}
      />
      <ToggleSidebar
        headerTitle="Referral detail"
        isOpen={openReReferralDetail.referrals}
        toggleSidebar={() => onToggleTemplate('referrals')}
      >
        <ReferralDetail />
      </ToggleSidebar>
    </Box>
  )
}

export default ReferralsTable
