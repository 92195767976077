import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import Paper from 'components/paper'
import { PaymentSkeleton } from 'components/skeleton'
import { Switch } from 'components/switch'
import { COLORS } from 'styles/colors'
import { StylesScrollBar } from 'styles/utils'
import { CompanyDetail } from 'types/companies'
import { formatDate } from 'utils'
import { CARDS } from 'utils/catalogs'
import AddmethodDialog from './addMethodDialog'
import useAddCard from './addMethodDialog/useAddCard'
import useActiveCard from './hooks/useActiveCard'
import usePaymentMethod from './hooks/usePaymentMethod'
import useUnRegisterCard from './hooks/useUnRegisterCard'
import UnregistrerMethod from './unRegisterMethodDialog'

export default function PaymnetMethod({ company }: { company: CompanyDetail }) {
  const { onSubmit, onOpenDialog, onCloseDialog, isOpen, isLoading } =
    useAddCard(company)

  const { isLoading: loadingMethods, paymentMethods } = usePaymentMethod({
    status: 'ACTIVE'
  })

  const { onSelectedMethod } = useUnRegisterCard()

  const {
    isLoading: isLoadingActive,
    checked,
    onChangeValue
  } = useActiveCard(paymentMethods)

  return (
    <Box
      height="calc(100vh - 13em)"
      sx={{
        overflow: 'auto',
        ...StylesScrollBar
      }}
    >
      <Stack
        top={0}
        zIndex={1}
        paddingLeft={2}
        bgcolor="white"
        position="sticky"
        paddingBottom="5px"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography
          variant="h5"
          fontWeight="500"
          fontSize={{ xs: '18px', sm: '20px' }}
        >
          Payment methods
        </Typography>
        <Button
          variant="text"
          onClick={onOpenDialog}
          startIcon={<EditIcon htmlColor={COLORS.main} />}
          sx={{
            marginRight: '10px',
            alignSelf: 'flex-end'
          }}
        >
          Add new
        </Button>
      </Stack>
      <Divider />
      <Box paddingX={2}>
        {loadingMethods || isLoadingActive ? (
          <PaymentSkeleton />
        ) : (
          <Box
            gap={4}
            display="grid"
            paddingTop={6}
            gridAutoRows="auto"
            gridTemplateColumns="repeat(auto-fill, minmax(min(100%, 21rem), 1fr));"
          >
            {paymentMethods.map(method => {
              const {
                id,
                label,
                status,
                type,
                default: isDefault,
                last_four_digits: lastFourDigits,
                expiration_date: expirationDate
              } = method
              const CardIcon = CARDS[type || 'default']
              return (
                <Stack
                  key={id}
                  paddingX={4}
                  paddingY={2}
                  height={120}
                  elevation={1}
                  borderRadius={4}
                  component={Paper}
                  justifyContent="space-around"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack direction="row" marginBottom={1} alignItems="center">
                      <Typography
                        variant="h5"
                        fontSize={18}
                        marginRight={2}
                        fontWeight={isDefault ? 500 : 300}
                        color={isDefault ? COLORS.green1 : COLORS.gray2}
                      >
                        {isDefault ? 'DEFAULT' : status}
                      </Typography>
                      <Switch
                        name={`switch-${id}`}
                        disabled={isLoadingActive}
                        checked={checked === `switch-${id}`}
                        onChange={e => onChangeValue(e.target.name)}
                      />
                    </Stack>
                    <IconButton onClick={() => onSelectedMethod(method)}>
                      <DeleteOutlineIcon htmlColor={COLORS.gray2} />
                    </IconButton>
                  </Stack>
                  <Box>
                    <Typography
                      variant="h5"
                      color={COLORS.gray2}
                      marginBottom={1}
                    >
                      {label}
                    </Typography>
                    <Typography variant="body2" color={COLORS.gray2}>
                      ************{lastFourDigits}
                    </Typography>
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h5" color={COLORS.gray2}>
                      {formatDate(expirationDate || '', 'yy / MM')}
                    </Typography>
                    <CardIcon />
                  </Stack>
                </Stack>
              )
            })}
          </Box>
        )}
      </Box>

      <AddmethodDialog
        open={isOpen}
        onSubmit={onSubmit}
        isLoading={isLoading}
        onClose={onCloseDialog}
      />
      <UnregistrerMethod />
    </Box>
  )
}
