import useNavigation from 'hooks/useNavigation'
import { JobUpdateBonusTemplate } from 'types/bonusTemplate'
import CreateBonusFromTemplateStep from '../CreateBonusFromTemplateStep'
import EditBonusStep from '../EditBonusStep'
import NewBonusStep from '../NewBonusStep'

export default function BonusConfigStep({
  handleChange,
  values,
  setFieldValue,
  setValues,
  getFieldProps,
  hasBonus,
  errors,
  isValid
}: JobUpdateBonusTemplate) {
  const { searchParams } = useNavigation()

  if (searchParams.bonusId > 0 && hasBonus) {
    return (
      <EditBonusStep
        errors={errors}
        handleChange={handleChange}
        values={values}
        isValid={isValid}
        setFieldValue={setFieldValue}
        setValues={setValues}
        getFieldProps={getFieldProps}
      />
    )
  }
  if (searchParams.bonusId > 0 && !hasBonus) {
    return (
      <CreateBonusFromTemplateStep
        errors={errors}
        handleChange={handleChange}
        values={values}
        isValid={isValid}
        setFieldValue={setFieldValue}
        setValues={setValues}
        getFieldProps={getFieldProps}
      />
    )
  }
  return (
    <NewBonusStep
      errors={errors}
      handleChange={handleChange}
      values={values}
      setFieldValue={setFieldValue}
      setValues={setValues}
      isValid={isValid}
      getFieldProps={getFieldProps}
    />
  )
}
