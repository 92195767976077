import { styled, keyframes } from '@mui/material/styles'
import { COLORS } from 'styles/colors'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerContainer = styled<{ size?: string } & any>('div')`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid ${COLORS.honey4};
  border-radius: 50%;
  width: ${props => props.size || '2.5rem'};
  height: ${props => props.size || '2.5rem'};
  animation: ${spin} 0.6s linear infinite;
`

export default function Spinner({ size }: { size?: string }) {
  return <SpinnerContainer size={size} />
}
