import { Role, UserType } from 'types/auth'
import { KeyTypeCard } from 'utils/validationCard'
import { Pagination } from './general'
export type EntityName = 'job' | 'employee' | 'candidate'

export type Field = Array<{ value: string; label: string }>

export type Fields = {
  job: Field
  employee: Field
  candidate: Field
}

export enum Step {
  Employee = 'employee',
  Job = 'job',
  Candidate = 'candidate'
}

export type EntityList =
  | 'id'
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'phone_area_code'
  | 'phone'
  | 'password_hash'
  | 'avatar'
  | 'birthday'
  | 'gender'
  | 'type'
  | 'country'
  | 'last_login'
  | 'reset_token'
  | 'expo_token'
  | 'firebase_token'
  | 'status'
  | 'nip_email'
  | 'nip_phone'
  | 'number_logins'
  | 'reset_token_at'
  | 'email_validation_at'
  | 'phone_validation_at'
  | 'staff'
  | 'role'
  | 'in_platform'
  | 'referred_jobs'
  | 'inserted_at'
  | 'updated_at'
  | 'password'

export type BeegoppEntity = Array<EntityList>

export type CompanyConfigDetail = {
  id: number
  inserted_at: Date
  updated_at: Date
  api_name: string
  enable: boolean
  env: Env
  mapped_fields: {
    job: Record<string, string>
    employee: Record<string, string>
    candidate: Record<string, string>
  }
}

export interface CompanyEntities {
  label: string
  dateLastModified: Date
  entity: string
  entityMetaUrl: string
  fields: Array<Entity>
}

export type ConfigurationTosend = {
  config: Config
}

export type ImageUpdate = {
  header: string
  logo: string
  description: string
}

type Config = {
  enable?: string
  api_name?: string
  mapped_fields: Partial<MappedFields>
  env?: Env
}

type MappedFields = {
  job: Record<string, Array<string>>
  employee: Record<string, Array<string>>
  candidate: Record<string, Array<string>>
}

type Env = {
  username: string
  password: string
  client_id: string
  client_secret: string
}

export type UserChangePassword = {
  last: string
  new: string
}

export type UserInformation = {
  birthday: Date
  email: string
  avatar: string
  first_name: string
  id: number
  inserted_at: Date
  last_login: Date
  phone: string
  phone_area_code: string
  role: Role
  last_name: string
  type: UserType
  updated_at: Date
}

export interface TangoResponse {
  beegopp_tango_data: BeegoppTango
  tango_response: TangoAccount
}

export interface PaymentMethodResponse extends Pagination {
  entries: Array<PaymentMethod>
}

export type Transaction = {
  current_tango_balance: number
  total_ach_funds: number
  total_credit_card_funds: number
  total_paid: number
}

export type PaymentMethod = {
  id: number
  default: boolean
  expiration_date: Date | string
  label: string
  last_four_digits: string
  status: 'ACTIVE' | 'DEACTIVATED'
  type: KeyTypeCard
}

type TangoAccount = {
  accounts: Array<Account>
  createdAt: Date
  customerIdentifier: string
  displayName: string
  status: string
}

type Account = {
  accountIdentifier: string
  accountNumber: string
  createdAt: Date
  displayName: string
  status: string
}

type BeegoppTango = {
  account_identifier: string
  budget: string
  credit_card_token: string
  customer_identifier: string
  tango_balance: string
}

type Entity = {
  associatedEntity?: AssociatedEntity
  confidential?: boolean
  hideFromSearch?: boolean
  optionsType?: string
  optionsUrl?: string
  type: string
  name: string
  label: string
  dataType: string
  fields: Array<{ [key in string]: unknown }>
}

type AssociatedEntity = {
  dateLastModified: Date
  entity: string
  entityMetaUrl: string
  label: string
  fields: Array<Entity>
}
