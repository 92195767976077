import { LoadingButton } from '@mui/lab'
import { Button, Typography, Stack, Box } from '@mui/material'
import usePaymentMethod from 'app/profile/myCompany/paymentMethod/hooks/usePaymentMethod'
import CurrencyField from 'components/currencyField'
import Paper from 'components/paper'
import { FundSkeleton } from 'components/skeleton'
import { Form, Formik } from 'formik'
import { COLORS } from 'styles/colors'
import { PaymentMethod } from 'types/profile'
import { formatAmount, formatDate } from 'utils'
import { CARDS } from 'utils/catalogs'
import EmptyCard from './emptyCard'
import useCreditCard, { getFee, validationSchema } from './useCreditCard'

export default function CreditCard() {
  const { onSubmit, onCloseDialog, isLoading } = useCreditCard()
  const { isLoading: loadingMethods, paymentMethods } = usePaymentMethod({
    default: true
  })

  const filtereedMethod = paymentMethods[0] || ({} as PaymentMethod)

  const CardIcon = CARDS[filtereedMethod.type || 'default']

  return (
    <>
      {loadingMethods ? (
        <FundSkeleton />
      ) : paymentMethods.length > 0 ? (
        <>
          <Stack
            paddingX={4}
            paddingY={2}
            height={120}
            marginBottom={6}
            elevation={1}
            borderRadius={4}
            component={Paper}
            justifyContent="space-around"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" marginBottom={1} alignItems="center">
                <Typography
                  variant="h5"
                  fontSize={18}
                  marginRight={2}
                  fontWeight={500}
                  color={COLORS.green1}
                >
                  Default
                </Typography>
              </Stack>
            </Stack>
            <Box>
              <Typography variant="h5" color={COLORS.gray2} marginBottom={1}>
                {filtereedMethod.label}
              </Typography>
              <Typography variant="body2" color={COLORS.gray2}>
                ************{filtereedMethod.last_four_digits}
              </Typography>
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" color={COLORS.gray2}>
                {formatDate(filtereedMethod.expiration_date || '', 'yy / MM')}
              </Typography>
              <CardIcon />
            </Stack>
          </Stack>
          <Formik
            validationSchema={validationSchema}
            initialValues={{ amount: '' }}
            onSubmit={onSubmit}
          >
            {({ errors, touched, values, setFieldValue }) => {
              const feeValue = getFee(3.5, Number(values.amount) || 0)
              return (
                <Form>
                  <Typography variant="h5" paddingBottom={4}>
                    Funding amount
                  </Typography>
                  <Stack position="relative" marginBottom={5}>
                    <CurrencyField
                      name="amount"
                      placeholder="$2,000"
                      allowNegativeValue={false}
                      onValueChange={(value, name) => {
                        setFieldValue(name || 'amount', value)
                      }}
                    />
                    {errors.amount && touched.amount ? (
                      <Typography
                        variant="h6"
                        color={COLORS.red1}
                        position="absolute"
                        margin="60px 0 0 0"
                        top="-10px"
                      >
                        {errors.amount}
                      </Typography>
                    ) : null}
                  </Stack>
                  <Stack
                    direction="row"
                    marginBottom={2}
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Fee(3.50%)</Typography>
                    <Typography variant="h5">
                      {formatAmount(feeValue)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">Total payment amount</Typography>
                    <Typography variant="h5">
                      {formatAmount(Number(values.amount) + feeValue || 0)}
                    </Typography>
                  </Stack>
                  <Stack gap={2} direction="row" marginTop={10}>
                    <Button fullWidth onClick={onCloseDialog}>
                      Cancel
                    </Button>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Deposit funds
                    </LoadingButton>
                  </Stack>
                </Form>
              )
            }}
          </Formik>
        </>
      ) : (
        <EmptyCard onCloseDialog={onCloseDialog} />
      )}
    </>
  )
}
