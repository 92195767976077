import { hookstate, useHookstate } from '@hookstate/core'
import { RechargeCreditCard } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { get } from 'utils'
import * as yup from 'yup'

const initialState = hookstate({
  isOpen: false
})

export const validationSchema = yup.object({
  amount: yup.number().required('Amount is required')
})

export const getFee = (fee: number, amount: number) => {
  if (amount === 0) {
    return amount
  }
  return (fee / 100) * amount
}

export default function useCreditCard() {
  const { onNotify } = useNotification()
  const { isOpen } = useHookstate(initialState)

  const { isLoading, mutate } = useMutation(
    (data: { amount: number }) => RechargeCreditCard(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Amount added successfully'
        })
      },
      onError: error => {
        const errorMessage = get(
          error,
          'response.data.errors.detail.errors[0].message'
        )
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onOpenDialog = () => isOpen.set(true)
  const onCloseDialog = () => isOpen.set(false)

  const onSubmit = (values: { amount: string }) => {
    const feeValue = getFee(3.5, Number(values.amount) || 0)
    mutate({
      amount: Number(values.amount) + feeValue
    })
  }

  return {
    isOpen: isOpen.get(),
    isLoading,
    onSubmit,
    onOpenDialog,
    onCloseDialog
  }
}
