import { useState } from 'react'
import { GetCompanyConfigDetail } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { EntityName, Fields } from 'types/profile'
import { get, isEmpty } from 'utils'
import useSettings from './useSettings'

const validateFields = (fields: Record<string, string>) =>
  Object.entries(fields).map(([key, value]) => {
    const label = key.replace(/_/g, ' ')
    return { value: value[0], label }
  })

export default function useConfigDetail() {
  const { user } = useAuth()
  const { onSaveDataUpdate } = useSettings()
  const { onNotify } = useNotification()
  const [isEnable, setIsEnable] = useState(false)

  const [hasMappedFields, setHasMappedFields] = useState(false)
  const [mappedFields, setMappedFields] = useState<Fields>({} as Fields)

  const { isLoading, isFetching } = useQuery(
    ['COMPANY_DETAIL_CONFIG', user.company_id, user.config_id],
    () => GetCompanyConfigDetail(user.company_id, user.config_id),
    {
      enabled: !!user.config_id,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: data => {
        const hasMapped = Object.keys(data.mapped_fields).some(
          key => !isEmpty(data.mapped_fields[key as EntityName])
        )
        setHasMappedFields(hasMapped)
        setMappedFields({
          job: validateFields(data.mapped_fields.job || {}),
          employee: validateFields(data.mapped_fields.employee || {}),
          candidate: validateFields(data.mapped_fields.candidate || {})
        })
        onSaveDataUpdate(data.mapped_fields)
        setIsEnable(data.enable)
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ message: errorMessage, severity: 'error' })
      }
    }
  )

  return {
    isLoading: isFetching || isLoading,
    isEnable,
    mappedFields,
    hasMappedFields
  }
}
