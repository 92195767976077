import { useState } from 'react'
import { RechargeAchWire } from 'api'
import { useNotification } from 'components/notification/useNotification'
import { useMutation } from 'react-query'
import { get } from 'utils'
import * as yup from 'yup'

export const validationSchema = yup.object({
  amount: yup.string().required('Amount is required'),
  file: yup.string().required('File is required')
})
export default function useAch() {
  const { onNotify } = useNotification()
  const [base64, setBase64] = useState<
    string | ArrayBuffer | null | undefined
  >()

  const { isLoading, mutate } = useMutation(
    (data: { amount: string; file: string }) => RechargeAchWire(data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Amount added successfully'
        })
      },
      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      }
    }
  )

  const onDropFile = async ({ file }: { file: File[] }) => {
    const fileToLoad = file[0]
    const fileReader = new FileReader()
    fileReader.onload = function (fileLoadedEvent) {
      const base64 = fileLoadedEvent?.target?.result
      setBase64(base64)
    }
    fileReader.readAsDataURL(fileToLoad)
  }

  const onSubmit = (values: { amount: string }) => {
    const data = {
      email: '',
      amount: '',
      accountnumber: '',
      Wiretransfernumber: ''
    }
    console.log('>>>>>>>>>>>', { values, base64, data, mutate })
  }

  return { isLoading, onSubmit, onDropFile }
}
