import { useEffect } from 'react'
import { useState } from 'react'
import { Box } from '@mui/material'
import Stepper from 'components/stepper'
import useStepper from 'components/stepper/useStepper'
import { Formik } from 'formik'
import { BonusTemplateDetails } from 'types/bonusTemplate'
import { JobDetail } from 'types/jobs'
import * as yup from 'yup'
import useFetchDetail from '../../../hooks/useFetchDetail'
import BonusDetailsStep from '../BonusDetailsStep'
import BonusConfigStep from '../Steps/BonusConfigStep'
import SelectBonusStep from '../Steps/SelectBonusStep'
import ValidateStep from '../Steps/validateStep'
import useTemplate from '../useTemplate'
export default function Templates() {
  const [steps, setSteps] = useState<Array<string>>([])
  const { details, isLoading, isFetching } = useFetchDetail()
  const initialValues: BonusTemplateDetails = {} as BonusTemplateDetails
  const { onNextStep } = useStepper()

  const schema = yup.object({
    entries: yup.object({
      description: yup.string().required('Description is required'),
      bonus_config: yup.array().of(
        yup.object().shape({
          amount: yup
            .number()
            .max(2000, 'Max amount is 2000')
            .typeError('Amount must be a number')
            .required('Amount is required'),
          days: yup.number().min(0).required('Days is required')
        })
      )
    })
  })

  const { isSent } = useTemplate()
  const { bonus } = details

  const removeStep = () => {
    if (bonus) {
      setSteps(['Configuration', 'Review'])
    } else {
      setSteps(['Set up a bonus', 'Configuration', 'Review'])
    }
  }
  useEffect(() => {
    removeStep()
  }, [])
  if (isLoading || isFetching) {
    return <div>loading</div>
  }
  return (
    <Box height="calc(100% - 96px)">
      {isSent ? (
        <BonusDetailsStep />
      ) : (
        <Formik
          enableReinitialize
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={() => {
            onNextStep()
          }}
        >
          {({
            handleChange,
            values,
            setFieldValue,
            getFieldProps,
            setValues,
            isValid,
            errors
          }) => (
            <>
              {bonus ? (
                <Stepper steps={steps}>
                  <BonusConfigStep
                    errors={errors}
                    hasBonus={Boolean(bonus)}
                    values={values}
                    isValid={isValid}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                    setValues={setValues}
                  />
                  <ValidateStep
                    hasBonus={Boolean(bonus)}
                    values={values}
                    jobDetail={details as JobDetail}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                  />
                </Stepper>
              ) : (
                <Stepper steps={steps}>
                  <SelectBonusStep />
                  <BonusConfigStep
                    errors={errors}
                    hasBonus={Boolean(bonus)}
                    values={values}
                    isValid={isValid}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                    setValues={setValues}
                  />
                  <ValidateStep
                    hasBonus={Boolean(bonus)}
                    values={values}
                    jobDetail={details as JobDetail}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                  />
                </Stepper>
              )}
            </>
          )}
        </Formik>
      )}
    </Box>
  )
}
