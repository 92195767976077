import { useState } from 'react'
import { AddCompanyConfig } from 'api'
import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { ConfigurationTosend } from 'types/profile'
import { get } from 'utils'
import * as yup from 'yup'
import useDisconnect from './useDisconnect'

type Field = 'clientId' | 'clientSecret' | 'password' | 'username'

export const initialValues = {
  clientId: '',
  clientSecret: '',
  username: '',
  password: ''
}

export const validationSchema = yup.object({
  clientId: yup.string().required('Required'),
  clientSecret: yup.string().required('Required'),
  username: yup.string().required('Required'),
  password: yup.string().required('Required')
})

export default function useConnect() {
  const { user, session, onUpdateUser } = useAuth()
  const { onNotify } = useNotification()
  const { onCloseSidebar } = useDisconnect()
  const [showField, setShowField] = useState({
    clientId: false,
    clientSecret: false,
    password: false,
    username: false
  })

  const query = useQueryClient()

  const { mutate } = useMutation(
    (data: ConfigurationTosend) => AddCompanyConfig(user.company_id, data),
    {
      onSuccess: data => {
        onNotify({ severity: 'success', message: 'Conexion success' })

        onUpdateUser({
          token: session.token,
          user: {
            ...user,
            config_id: data.id
          }
        })

        query.invalidateQueries([
          'COMPANY_DETAIL_CONFIG',
          data.company_id,
          data.config_id
        ])
      },

      onError: error => {
        const errorMessage = get(error, 'response.data.errors.detail')
        onNotify({ severity: 'error', message: errorMessage })
      },
      onSettled: () => {
        onCloseSidebar()
      }
    }
  )

  const onShowField = (field: Field) => {
    setShowField(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }))
  }

  const onSubmit = (values: typeof initialValues) => {
    const data = {
      api_name: 'Bullhorn',
      enable: 'false',
      mapped_fields: {
        job: {
          name: ['title'],
          site: ['location'],
          type: ['employmentType'],
          owner: ['owner', 'firstName'],
          branch: ['branchCode'],
          address: ['address', 'address1'],
          id_source: ['id'],
          recluiter: ['responseUser', 'firstName'],
          applicants: ['submissions', 'total'],
          salary_low: ['customFloat1'],
          description: ['publicDescription'],
          salary_high: ['salary'],
          company_name: ['clientCorporation', 'name'],
          creation_date: ['dateAdded'],
          status_source: ['status'],
          open_positions: ['numOpenings']
        },
        employee: {} as Record<string, Array<string>>,
        candidate: {} as Record<string, Array<string>>
      },
      env: {
        client_id: values.clientId,
        client_secret: values.clientSecret,
        username: values.username,
        password: values.password
      }
    }
    mutate({ config: data })
  }

  return { onSubmit, onShowField, onCloseSidebar, showField }
}
