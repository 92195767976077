import { Box, Button, Stack, Typography } from '@mui/material'
import Stepper from 'components/stepper'
import ToggleSidebar from 'components/toggleSidebar'
import { COLORS } from 'styles/colors'
import CandidatesSettings from '../candidatesSettings'
import EmployeeSetting from '../employeeSettings'
import useSettings from '../hooks/useSettings'
import JobsSettings from '../jobsSettings'

const steps = ['Employee', 'Jobs', 'Candidates']

export default function EmptySettings() {
  const { onToggleConfig, openConfig } = useSettings()

  return (
    <Box paddingY={2}>
      <Stack alignItems="center" margin="50px auto">
        <Box component="figure" width="58px">
          <img src="/images/settingImage.png" alt="Setting" width="100%" />
        </Box>
        <Typography variant="h3" color={COLORS.blue9} paddingBottom="20px">
          Configured your field maps
        </Typography>
        <Button
          variant="contained"
          sx={{ width: '120px' }}
          onClick={() => onToggleConfig('addConfig')}
        >
          Configured
        </Button>
      </Stack>
      <ToggleSidebar
        minWidth={650}
        isOpen={openConfig.addConfig}
        headerTitle="Field map configuration"
        toggleSidebar={() => onToggleConfig('addConfig')}
      >
        <Stepper steps={steps}>
          <EmployeeSetting />
          <JobsSettings />
          <CandidatesSettings />
        </Stepper>
      </ToggleSidebar>
    </Box>
  )
}
