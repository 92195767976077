import { useNotification } from 'components/notification/useNotification'
import useAuth from 'hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { get } from 'utils'
import * as yup from 'yup'
import { CreateEmployeeRequest } from '../../../api/index'
import { SendEmployeeRequest, ListEmployees } from '../Types/Employees'

export const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone_area_code: '',
  phone: '',
  role: '',
  staff: ''
}

export const validationSchema = yup.object({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  email: yup.string().email('Email invalid').required('Required'),
  phone: yup.string().required('Required'),
  role: yup.string().required('Required'),
  staff: yup.string().required('Required')
})

export default function useCreateEmployees() {
  const { onNotify } = useNotification()
  const { user } = useAuth()
  const query = useQueryClient()

  const { isLoading, mutate } = useMutation(
    (data: SendEmployeeRequest) => CreateEmployeeRequest(user.company_id, data),
    {
      onSuccess: () => {
        onNotify({
          severity: 'success',
          message: 'Invitations sent'
        })
        query.invalidateQueries('EMPLOYEE')
      },
      onError: (error, variables) => {
        const resError: Record<string, Array<string>> = get(
          error,
          'response.data.errors.detail[0].errors'
        )
        const variablesKeys = Object.keys(variables.employees[0])

        const errorByKey = variablesKeys
          .map(key => {
            return { message: resError[key], key }
          })
          .filter(item => item.message)

        const message =
          typeof errorByKey[0].message === 'object'
            ? `${errorByKey[0].key} ${errorByKey[0].message[0]}`
            : `${errorByKey[0].key} ${errorByKey[0].message}`

        onNotify({ severity: 'error', message })
      }
    }
  )

  const onCreateEmployeeRequest = (
    employeeTemplate: ListEmployees & {
      role?: 'employee' | 'market_manager' | 'rewards_manager'
    }
  ) => {
    const data = {
      ...employeeTemplate,
      email: employeeTemplate.email.toLowerCase(),
      role: employeeTemplate.role,
      phone: employeeTemplate.phone.replace(
        employeeTemplate.phone_area_code,
        ''
      )
    }
    mutate({ employees: [data] })
  }

  return {
    onCreateEmployeeRequest,
    isLoading,
    onNotify
  }
}
